html,
body,
#root {
  height: 100%;
}

button:focus {
  outline: 0;
}

.App {
  display: flex;
  flex-flow: column;
  height: 100%;
}

/* * fix pour les icones trop grandes */

#gps .gjs-clm-tag-status,
#gps .gjs-clm-tag-close {
  width: 12px;
  height: 12px;
}

#gps .gjs-clm-tags-btn {
  width: 24px;
}

#gps .gjs-clm-tags {
  display: none;
}

#gps .custom-hidden {
  display: none;
}

#gps .gjs-btnt.gjs-pn-active,
#gps .gjs-color-active,
#gps .gjs-pn-btn.gjs-pn-active,
#gps .gjs-pn-btn:active,
#gps .gjs-block:hover {
  color: rgb(2, 152, 214);
}

#gps .gjs-pn-buttons {
  justify-content: center;
}

#react-confirm-alert .react-confirm-alert-body > h1 {
  color: #444;
}

#react-confirm-alert .react-confirm-alert-button-group {
  justify-content: space-evenly;
}

#react-confirm-alert .react-confirm-alert-button-group > button {
  background: rgb(2, 152, 214);
}

#___reactour span[data-tour-elem="badge"],
#___reactour button[data-tour-elem="dot"].reactour__dot--is-active {
  background: rgb(2, 152, 214);
}

#___reactour div[data-tour-elem="controls"] {
  justify-content: center;
}

#___reactour div[role="dialog"] {
  line-height: 20px;
}

#___reactour button.reactour__close {
  top: 16px;
  right: 16px;
}
